const DocMenuConfig = [
  {
    pages: [
      {
        heading: 'dashboard',
        route: '/dashboard',
        svgIcon: '/media/icons/duotone/Home/Home2.svg',
        fontIcon: 'bi-app-indicator',
      },
      {
        heading: 'Get Tenant Reference',
        route: '/tenant/overview',
        svgIcon: '/media/icons/duotone/General/User.svg',
        fontIcon: 'bi-person-lines-fill',
      },
      {
        heading: 'Request Tenant Reference',
        route: '/tenant/request',
        svgIcon: '/media/icons/duotone/Interface/Envelope.svg',
        fontIcon: 'bi-app-indicator',
      },
      // {
      //   heading: "User Management",
      //   route: "#",
      //   svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
      //   fontIcon: "bi-app-indicator",
      // },
    ],
  },
  // {
  //   heading: "Tenant Report",
  //   route: "/tenant-report",
  //   pages: [
  //     {
  //       heading: "Submit Report",
  //       svgIcon: "/media/icons/duotone/Files/File-done.svg",
  //       fontIcon: "bi bi-bookmarks-fill",
  //       route: "/tenant-report/submit",
  //     },
  //     {
  //       heading: "Request Report",
  //       route: "#",
  //       svgIcon: "/media/icons/duotone/Files/Compiled-file.svg",
  //       fontIcon: "bi-archive",
  //     },
  //     {
  //       heading: "Search Report",
  //       route: "/tenant-report/search",
  //       svgIcon: "/media/icons/duotone/General/Search.svg",
  //       fontIcon: "bi-layers",
  //     },
  //   ],
  // },
];

export default DocMenuConfig;
